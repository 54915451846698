<template>
  <v-form
    ref="form"
    class="multi-col-validation"
    method="post"
    action="/"
    lazy-validation
    @submit.prevent="saveSettings()"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        System settings
      </h1>
      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Notification phone number
        </h2>
      </v-card-text>
      <v-card-text>
        <v-label>Phone numbers that will be notified when a reseller initiates a withdrawal</v-label>
        <v-row
          v-for="(contact, index) in form.super_admin_contacts"
          :key="index"
        >
          <v-col md="4">
            <v-text-field
              v-model="form.super_admin_contacts[index]"
              label="Phone number"
              placeholder="07*********"
              dense
              outlined
              :rules="optionalPhoneRules"
              :loading="isLoading"
              :disabled="isLoading"
              :max="15"
              class="shrink ma-3"
            ></v-text-field>
          </v-col>
          <v-col md="6">
            <v-btn
              v-show="index === 0"
              class="ml-2 mt-2"
              small
              outlined
              fab
              color="primary"
              @click="addAdminContact"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              v-show="index != 0"
              class="ml-2 mt-2"
              small
              outlined
              fab
              color="primary"
              @click="removeAdminContact(index)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          type="submit"
          :loading="form.busy"
        >
          Save changes
        </v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import Form from 'vform'
import { mdiPlus, mdiClose } from '@mdi/js'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  data() {
    return {
      isLoading: false,
      form: new Form({
        super_admin_contacts: [''],
      }),
      icons: {
        mdiPlus,
        mdiClose,
      },
    }
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    addAdminContact() {
      this.form.super_admin_contacts.push('')
    },
    removeAdminContact(index) {
      if (this.form.super_admin_contacts.length > 1) {
        this.form.super_admin_contacts.splice(index, 1)
      }
    },
    getSettings() {
      this.isLoading = true
      this.form
        .get('super-admin-settings')
        .then(response => {
          const { super_admin_contacts } = response.data

          // extract only phone numbers from the response
          this.form.super_admin_contacts = super_admin_contacts.map(contact => contact.phone)
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        }).finally(() => {
          this.isLoading = false
        })
    },
    saveSettings() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('super-admin-settings')
          .then(response => {
            this.$notification.success('Updated successfully')
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
  },
}

</script>
